@import "./_variables.scss";

.ledger-label {
  display: flex;
  position: absolute;
  right: 0;
  height: 40px;
  width: 124px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
