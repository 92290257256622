@import "./styles/header.scss";
@import "./styles/status.scss";
@import "./styles/dashboard.scss";
@import "./styles/tab.scss";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

@font-face {
  font-family: OpenSans-Bold;
  src: url(assets/fonts/OpenSans-Bold.ttf);
}

@font-face {
  font-family: OpenSans-Light;
  src: url(assets/fonts/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSans-Regular;
  src: url(assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSans-SemiBold;
  src: url(assets/fonts/OpenSans-SemiBold.ttf);
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e1f2c !important;
}

code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

// Default classes from Bootstrap for general usage START

.container-v-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}

.row-h-padding {
  padding-left: 16px;
  padding-right: 16px;
}

.row-v-padding {
  padding-top: 8px;
  padding-bottom: 8px;
}

.col-v-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}

.col-v-padding-56 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.card-v-padding-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.card-h-padding-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.card-v-padding-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.card {
  background-color: #272831 !important;
}

.text-capitalized {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-heading-dark {
  color: #767676 !important;
  font-weight: 700;
  font-size: 18px;
}

.text-heading-white {
  color: #ffffff !important;
  font-weight: 700;
  font-size: 18px;
}

.text-title-dark {
  color: #767676 !important;
  font-weight: 700;
  font-size: 16px;
}

.text-title-white {
  color: #ffffff !important;
  font-weight: 700;
  font-size: 16px;
}

.text-title-link {
  color: #147efb !important;
  font-weight: 700;
  font-size: 16px;
}

.text-title-link:hover {
  text-decoration: underline;
}

.text-para-dark {
  color: #767676 !important;
  font-weight: 200;
  font-size: 16px;
}

.text-para-white {
  color: #ffffff !important;
  font-weight: 200;
  font-size: 16px;
}

.text-sub-para-white {
  color: #ffffff !important;
  font-weight: 200;
  font-size: 12px;
}

.text-para-link {
  color: #147efb !important;
  font-weight: 200;
  font-size: 16px;
}

.text-para-link:hover {
  text-decoration: underline;
}

.text-para-green {
  color: #75daad !important;
  font-weight: 200;
  font-size: 16px;
}

.text-not-found {
  font-weight: 100;
  font-size: 72px;
}

.color-app {
  color: #342ead !important;
}

.color-dark {
  color: #767676 !important;
}

.color-white {
  color: #ffffff !important;
}

.color-green {
  color: #75daad !important;
}

.color-red {
  color: #fe346e !important;
}

.color-notfound {
  color: #e6e6e6 !important;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

.marging-right-8 {
  margin-right: 8px;
}

.marging-left-8 {
  margin-left: 8px;
}

.marging-right-16 {
  margin-right: 16px;
}

.marging-left-16 {
  margin-left: 16px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.dropdown-caret {
  max-width: 64px;
}

tr:hover {
  cursor: pointer;
}

th {
  padding-left: 0px !important;
  color: #ffffff !important;
}

td {
  padding-left: 0px !important;
  color: #ffffff !important;
}

button:focus {
  outline: none !important;
}

// Default classes from Bootstrap for general usage END
