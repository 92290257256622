@import "./_variables.scss";

.flat-button-blue {
  cursor: pointer;
  background-color: $lightBlue;
  display: flex;
  height: 32px;
  width: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.input-error {
  color: $red !important;
  font-size: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.tooltip-arrow[data-placement*="top"]::after {
  border-color: #272831 transparent transparent transparent !important;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-arrow[data-placement*="top"]::before {
  border-color: #272831 transparent transparent transparent !important;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}
