@import "./_variables.scss";

.nav {
  background-color: $mainBlue;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.nav-tab {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: #1e1f2c;
  border-bottom: none !important;
}

.nav-link {
  border: transparent !important;
  &:hover {
    background-color: $mainBlue !important;
  }
}

.nav-tab-link {
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff !important;
  min-width: 140px;
  border-radius: 0px;
  margin-right: 8px !important;
}

.nav-tab-link-active {
  background-color: $mainBlue !important;
}

.nav-tab-link-inactive {
  background-color: $inactiveColor !important;
}

.nav-link-inactive {
  color: $gray !important;
  font-weight: 700;
  font-size: 16px;
}

.nav-link-active {
  color: #ffffff !important;
  font-weight: 700;
  font-size: 16px;
}

/* For Highlight Beneath Nav Items When Active and Hover Starts*/
@media (min-width: 768px) {
  .nav {
    align-items: stretch !important;
  }

  .navbar-nav {
    align-items: stretch !important;
    height: 100% !important;
  }

  .nav-item {
    align-items: stretch !important;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    a {
      line-height: 66px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .nav-link-active {
    border-bottom: 2px solid #fff !important;
  }

  .nav-link-inactive {
    &:hover {
      transition: 0.3s;
      border-bottom: 2px solid #fff !important;
    }
  }
}
/* For Highlight Beneath Nav Items When Active and Hover Ends*/
